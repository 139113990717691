
@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica.eot?#iefix') format('embedded-opentype'), 
   url('Helvetica.woff') format('woff'), 
   url('Helvetica.ttf')  format('truetype'), 
   url('Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica-Bold.eot?#iefix') format('embedded-opentype'), 
   url('Helvetica-Bold.woff') format('woff'), 
   url('Helvetica-Bold.ttf')  format('truetype'), 
   url('Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
font-family: 'Helvetica';
src: url('Helvetica-Light.woff2') format('woff2'),
url('Helvetica-Light.woff') format('woff'),
url('Helvetica-Light.eot?#iefix') format('embedded-opentype'),
url('Helvetica-Light.svg#Helvetica-Light') format('svg');
font-weight: 300;
font-style: normal;
}
