// Here you can add other styles
.statusLabelActive {
  font-size: 14px;
  color: green;
  background: rgba(0, 299, 81, 0.1);
  border-radius: 5px;
  text-transform: capitalize;
  border-radius: 3px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 4px 11px;
}

.statusLabelWarning {
  font-size: 14px;
  color: #b49015;
  background: rgba(249, 177, 21, 0.1);
  border-radius: 5px;
  text-transform: capitalize;
  border-radius: 3px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 4px 11px;
}

.statusLabelInfo {
  font-size: 14px;
  color: rgb(129, 152, 174);
  background: rgb(239, 239, 239);
  border-radius: 5px;
  text-transform: capitalize;
  border-radius: 3px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 4px 11px;
}

.statusLabelInactive {
  font-size: 14px;
  color: #f93f3f;
  background: rgba(249, 63, 63, 0.1);
  text-transform: capitalize;
  border-radius: 3px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 4px 11px;
}

.close_reported_status {
  background-color: var(--lowAlertBg);
  color: var(--lowAlertText);
}

.open_reported_status {
  background-color: var(--highAlertBg);
  color: var(--highAlertText);
}

.reported_status {
  min-height: 21px;
  border-radius: 4px;
  padding: 4px 5px;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  min-width: 80px;
  max-width: 80px;
  font-size: 14px;
}

.c-header.c-header-fixed {
  z-index: 1001;
}

body {
  font-family: var(--fontFase);
  font-weight: normal;
  overflow-y: hidden;
}

.c-wrapper {
  padding-top: 57px;
  overflow-y: auto;
  height: calc(100vh - 57px);
}

.c-sidebar.c-sidebar-fixed {
  margin-top: 57px;
}

.mb-60 {
  margin-bottom: 40px;
}

.c-sidebar .c-sidebar-nav {
  margin-left: inherit !important;
  align-items: start !important;
  justify-content: normal !important;
}

.file-label {
  width: 500px !important;
  height: 200px;
  border: 2px dashed #3399ff;
  display: block !important;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .upload-icon {
    width: 80px;
    background-color: #3399ff;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.dashboardTiles {
  background: #ffffff;
  font-family: var(--fontFase);
  font-style: normal;
  box-shadow: 0px 4px 25px rgba(40, 77, 115, 0.08);
  border-radius: 8px;
  background-position: 285px -40px, 250px 50px, right bottom;
  background-repeat: no-repeat, no-repeat;
  flex: 1 1 0%;
  min-width: max-content;
  border-left: 5px solid transparent;
  &:active {
    background: white;
    color: #3ca5ed;
    border-left: 5px solid;
  }

  &:hover {
    color: #3ca5ed;
    border-left: 5px solid;
    .text-value-lg {
      color: #3ca5ed !important;
    }
    .content-heading {
      color: #3ca5ed !important;
    }
  }
  @media screen and (max-width: 1980px) {
    background-position: 165px -40px, 140px 50px, right bottom;
  }

  @media screen and (min-width:1980px) {
  .dashboard-tile-text {
    width: max-content !important
  }
  }

  @media screen and (max-width: 1336px) {
    background-position: 140px -40px, 115px 50px, right bottom;
    .dashboard-tile-text {
        width: 140px !important;
      }
  }
  @media screen and (max-width: 1024px) {
    background-position: 85px -40px, 39px 55px, right bottom;
    .dashboardTiles-content {
      font-size: 12px;
    }
    .dashboard-tile-text {
      width: 74px !important;
    }
  }
  @media screen and (max-width: 600px) {
    background-position: 85px -40px, 39px 55px, right bottom;
    min-width: 48%;
    flex: auto;
    &:last-child {
      background-position: 165px -40px, 140px 50px, right bottom;
    }
  }
  @media (max-width: 1099px) {
  }
  .dashboardTiles-body {
    justify-content: flex-start;
    padding: 30px 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--white);
    text-transform: uppercase;
    word-wrap: initial;

    .cardName {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: var(--white);
      text-transform: uppercase;
    }
  }
  .customCardFooter {
    padding: 0.75rem;
    hr {
      margin: 0 auto 10px auto;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
    .footerContent {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
    }
  }
}
.text-value-lg {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 30px;
  font-style: normal;
  color: #233e71;
  margin-bottom: 4px;

  @media (max-width: 1279px) {
    font-size: 1.2rem;
  }
}

.content-heading {
  display: flex;
  align-items: flex-start;
  color: #233e71;
  flex: 0 0 60%;
  flex-direction: column;
  justify-content: space-between;

  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.dart-widgets {
  margin-bottom: 1rem;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width:1433px) and(max-width:1625px ) {
    .dashboardTiles-body {
      padding: 20px 3px;
    }
  }
  @media screen and (min-width:1339px) and (max-width:1433px)  {
    .dashboardTiles-body {
      padding: 20px 3px;
    }
    .text-value-lg {
      font-size: 1.2rem;
    }
    .jvPhTU,.content-heading {
      font-size: 0.70rem;
    }
    .dashboard-tile-text {
      width: 140px !important;
      }
  }
  @media screen and  (max-width:1338px)  {
    .widgetHolder {
      gap: 5px;
  }
    .tileLoader {
      width: 40px;
      height: 30px;
    }
    .dashboardTiles-body {
      padding: 15px 1px;
    }
    .dashboardTiles-body div:first-child {
      border-radius: 2.5px;
      padding: 5px;
      margin-right: 5px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
    }
    .text-value-lg {
      font-size: 1.2rem;
      padding-top: 0.5rem;
      line-height: 0.75rem;
      margin-bottom: 1px;
    }
    .jvPhTU,
    .content-heading {
      font-size: 0.70rem;
    }
  }
  .dashboardTiles {
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      .dashboardTiles-content {
        font-size: 12px;
        padding-top: 10px;
      }
    }
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 600px) {
      min-width: 48%;
      flex: auto;
    }
  }
}
.activeDashboardTiles {
  box-sizing: border-box;
  color: var(--themeinfo);
  box-shadow: 0px 4px 14px #1d374e57 !important;
  border-left: 5px solid;
  .text-value-lg {
    color: #3ca5ed;
  }
  .content-heading {
    color: #3ca5ed;
  }
}
.c-sidebar {
  .c-sidebar-nav-item {
    svg {
      min-width: 24px;
      overflow: visible;
    }
    transition: all 0.3s ease-in-out;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 0px;
      width: 0px;
    }
  }

  .c-sidebar-nav-item:not(.submenu-list):not(.active):hover {
    position: relative;
    background: #f4f5f7;
    width: 98% !important;
    border-radius: 0% 75px 75px 0%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: #afafb1;
      transition: height 0.3s linear;
    }
  }

  .sidebar-link {
    .c-sidebar-nav-item {
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      .nav {
        padding: 5px 0 5px 13px !important;
      }
    }
  }

  .sidebar-link svg {
    width: 16px !important;
    min-width: 16px !important;
  }
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  transition: all 0.5s ease;
  position: relative;
}

.c-sidebar .c-sidebar-nav {
  padding-top: 16px !important;
  overflow-y: hidden;
}

.activeZakDashboardTiles {
  box-sizing: border-box;
  color: var(--themeinfo);
  box-shadow: 0px 4px 14px #1d374e57 !important;
  border-left: 7px solid #3ca5ed;

  .text-value-lg {
    color: #3ca5ed !important;
  }
  .text-value-lg + div {
      color: #3ca5ed !important;
    }
  .content-heading {
    color: #3ca5ed !important;
  }
}
.zakDashboardTile:hover {
      color: #3ca5ed;
      border-left: 7px solid #3ca5ed;
      .text-value-lg {
        color: #3ca5ed !important;
      }
      .text-value-lg + div{
        color: #3ca5ed !important;
      }
      .content-heading {
        color: #3ca5ed !important;
      }
}

.c-sidebar .c-sidebar-nav-item {
  margin-left: 0 !important;
  width: 100% !important;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  transition: all 0.5s ease;
  cursor: pointer;
  .nav {
    padding: 7px 0 7px 15px;
  }

  .text-margin {
    color: #333333 !important;
    div {
      align-items: flex-start !important;
    }
    .c-sidebar-nav-item {
      margin-top: 15px;
    }
  }

  margin-bottom: 2px;

  & > div {
    & > svg {
      path {
        fill: var(--white);
        fill-opacity: 1;
      }
    }
  }

  .annotation path {
    fill: transparent;
    fill-opacity: 1;
  }
}

.sidebar-link {
  li {
    div {
      svg {
        width: 16px;
      }
      div {
        span {
          font-size: small;
        }
      }
    }
    .d-flex.SideBar-icon {
      padding-left: 17px !important;
    }
  }
  .c-sidebar-nav-item {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    .nav {
      padding: 5px 0 5px 13px !important;
    }
    .text-margin {
      margin-left: 25px !important;
    }
  }
}

.c-sidebar {
  width: 256px;
  background: #ffffff;
  color: var(--lightBlue);
}

.c-sidebar .c-sidebar-nav-item.active {
  width: 98% !important;
  background: #e2f5fe;
  border-radius: 0% 75px 75px 0%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    // background: #62b5eb;
    transition: height 0.3s linear;
  }
  & > div {
    & > svg {
      fill: var(--themeinfo);
      path {
        fill: var(--themeinfo);
        fill-opacity: 1;
      }

      & + .text-margin {
        font-weight: 600;
        .text-margin {
          color: var(--themeinfo);
          font-weight: normal;
        }
      }
    }
  }
  .SideBar-icon {
    svg {
      fill: var(--themeinfo);
      path {
        fill: var(--themeinfo);
      }
    }
  }
  .text-margin {
    color: var(--themeinfo);
  }
  .annotation path {
    fill: transparent;
    fill-opacity: 1;
    stroke: #fff;
  }

  &.submenu-list {
    background-color: unset;

    & > div {
      & > svg {
        path {
          fill: var(--white);
          fill-opacity: 1;
        }

        & + .text-margin {
          font-weight: 400;

          .text-margin {
            font-weight: normal;
          }
        }
      }
    }
  }
}

.searchBar {
  background-color: rgba(31, 56, 108, 0.1);
  height: 38px;
  width: 477px;
  border-radius: 30px;
  padding-left: 35px;
  font-size: 14px;
  left: 797px;
  top: 21px;
  background-image: url('../assets/images/search.png');
  background-repeat: no-repeat;
  background-position: center left 14px;
  background-size: 13px;

  @media only screen and (min-width: 1280px) {
    width: 437px;
  }
}

.Toastify > div {
  z-index: 99999;
}

.gradientPurple {
  background-image: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #cf99ee 10.05%, #7716ae 148.92%);
}

.gradientGreen {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #72d9d0 10.05%, #03b196 148.92%);
}

.gradientBlue {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #75bdf8 10.05%, #047edf 148.92%);
}

.gradientOrange {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #faba91 10.05%, #fe7096 148.92%);
}

.gradientDarkBlue {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #88a4ee 10.05%, #184cd2 148.92%);
}

.gradientpink {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #ee88cf 10.05%, #d2188b 148.92%);
}

.gradientDarkGreen {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #72c472 10.05%, #407d40 148.92%);
}

.gradientDarkOrange {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #ff9e60 10.05%, #ff9e60 148.92%);
}

.gradientDarkRed {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #f77575 10.05%, #e83030 148.92%);
}

.gradientYellow {
  background: url('../assets/images/Ellipse.png'),
    url('../assets/images/Ellipse.png'),
    linear-gradient(89.77deg, #f5b63a 10.05%, #f6943a 148.92%);
}

.formfeilds {
  background: var(--borderBottomHeader);
  mix-blend-mode: normal;
  border-radius: 100px;
  font-family: var(--fontFase);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: var(--lightBlue) !important;
  padding: 24px 24px;
  border: none;

  &::placeholder {
    color: var(--lightBlue);
    opacity: 0.5;
  }

  &:focus {
    box-shadow: none;
    background: var(--borderBottomHeader);
  }
}

.form-group label {
  font-family: var(--fontFase);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--lightBlue);
  margin-bottom: 12px;
}

.form-group p {
  font-size: 14px;
}
.form-group .react-datepicker-wrapper {
  width: 100%;
}

.customDropdown {
  .btn {
    border-radius: 100px;
    font-family: var(--fontFase);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    border: none;
    background: var(--borderBottomHeader);
    color: var(--lightBlue);
    padding: 19px 24px;
    width: 100%;
    text-align: left;

    &::after {
      position: absolute;
      right: 24px;
      top: 20px;
    }

    &:focus {
      box-shadow: none;
    }

    span {
      &.lowOpacity {
        opacity: 0.5;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;

    .nav {
      &:hover {
        background-color: var(--background-dropdownoption);
      }
    }
  }
}

.custom_upload {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  width: fit-content;
  padding: 4px 6px 4px 8px;
  color: #7f7f7f;
  margin-top: 2px;
  background: white;
  height: 133px;

  .img-fluid {
    max-height: 133px;
    max-width: 133px;
  }
}

.custom_upload input[type='file'] {
  -webkit-appearance: hidden;
  display: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.customResetModal {
  z-index: 11111;
}
.customResetModal + .modal-backdrop {
  z-index: 1111;
}

.customModal {
  font-family: var(--fontFase);
  font-style: normal;
  box-shadow: 0px 4px 20px rgba(81, 129, 194, 0.3);
  border-radius: 14px;
  max-width: 420px;
  margin: 0 auto;

  .iconHolder {
    border: 3px solid #bcc3d3;
    display: inline-block;
    position: absolute;
    top: -60px;
    background-color: white;
    border-radius: 50%;
    padding: 35px;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 600px) {
      left: 50%;
    }
  }

  .modal-body {
    padding: 90px 61px 25px 61px;
    @media only screen and (max-width: 600px) {
      padding: 90px 41px 25px 41px;
    }

    .heading {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: var(--clientName);
      margin-bottom: 15px;
    }

    .content {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: var(--tableAlertGrey);
    }
  }

  .modal-footer {
    border-top: 0;
    justify-content: center;
  }
}
.themePreviewModel {
  .modal-dialog {
    max-width: 98%;
    height: 95%;

    .modal-content {
      height: 100%;
    }
    .preivewAlert {
      padding: 5px 12px;
      margin: 0px 16px;
    }
  }
}
.device-type-modal {
  font-family: Helvetica;
  font-style: normal;
  box-shadow: 0px 4px 20px rgba(81, 129, 194, 0.3);
  border-radius: 14px;
  max-width: 420px;
  margin: 0 auto;
  .modal-body {
    font-family: Helvetica;
    padding: 20px 30px;
    @media only screen and (max-width: 600px) {
      padding: 20px;
    }

    .heading {
      font-family: Helvetica;
      font-weight: 700;
      font-size: 18px;
      line-height: 20.7px;
      text-align: left;
      color: var(--clientName);
    }

    .content {
      margin-top: 35px;
      padding: 0 14px;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: left;
      color: var(--tableAlertGrey);
      label {
        font-size: 14px;
      }
      select {
        font-size: 16px;
      }
      span {
        color: var(--clientName);
      }
    }
  }

  .modal-footer {
    border-top: 0;
    justify-content: center;
  }
}
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: none;
}

.DateRangePicker_picker {
  z-index: 4 !important;
}
.dialog-action-btn {
  margin-left: 22px !important;
}
.no-data {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 400px;
  color: #7f7f7f;
}

.upload-file-wrapper {
  border: 1px dashed rgba(0, 0, 0, 0.2);
  width: '600px';
  color: 'black';
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload-file-wrapper p {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0;
}
.browse-btn {
  width: 150px;
  line-height: 50px;
  text-align: center;
  color: rgb(6, 140, 218);
  background-color: #b4dcf4;
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0px;
  cursor: pointer;
}
.remove-btn {
  border: 0px;
  background: none;
}
.browse-btn input[type='file'] {
  display: none;
}
.file-drop {
  width: 100%;
}
.file-drop-target {
  display: flex;
  align-items: center;
  svg {
    path {
      fill: #bdc4da !important;
    }
  }
}
.drop-file-detail {
  display: flex;
  justify-content: space-between;
}
.drop-file-detail p {
  font-size: 14px;
  color: #cdcdcd;
}
.form-description-text {
  font-size: 14px;
  color: #cdcdcd !important;
}
.import-file-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: '600px';
  color: 'black';
  padding: 20px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);

  svg {
    path {
      fill: #5c6474 !important;
    }
  }
}
.import-file-wrapper p {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0;
}
.import-btn {
  font-family: Arial, Helvetica, sans-serif;
  width: 107px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: #24a5ed;
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  svg {
    margin-right: 10px;
    path {
      fill: white !important;
    }
  }
}
.remove-btn {
  margin-left: auto;
  color: #5c6474;
  margin-right: 14px;
}
.import-btn input[type='file'] {
  display: none;
}
.import-drop {
  width: 100%;
}
.filimporte-drop-target {
  display: flex;
}
.import-file-detail {
  display: flex;
  justify-content: space-between;
}
.import-file-detail p {
  font-size: 14px;
  color: #cdcdcd;
}
.upload-file-wrapper .file-icon {
  width: 60px;
  margin-right: 20px;
  height: 60px;
}

.text-margin {
  color: #ffffff;
}

.c-icon-custom-size {
  fill: #ffffff;
}

.SidebarCollapse {
  margin-left: -12px;
  padding-left: 12px;
  max-width: 208px;
}

.c-sidebar .c-sidebar-nav-item.active {
  font-weight: 600;
}

.navdiv .nav:hover {
  text-decoration: unset;
  color: unset;
}
.navdiv .nav {
  cursor: pointer;
}
.c-sidebar .c-sidebar-nav-item.submenu-list div {
  flex-wrap: wrap;
  flex-grow: 1;
}

.sidebar-link {
  display: inline-flex;
}

.text-margin {
  color: #ffffff;
}

.c-icon-custom-size {
  fill: #ffffff;
}

.SideBar-icon {
  svg {
    fill: #333333;
    path {
      fill: #333333;
    }
  }
}

.py-3 {
  .DateRangePicker {
    width: max-content;
  }
  .filterIsActive {
    fill: var(--themeinfo);
  }
  .filterNotActive {
    fill: #999;
  }
  .filterButtonActive {
    position: relative;
    outline: none;
    .filterButtonClose {
      position: absolute;
      top: 2px;
      right: -4px;
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #ec4242;
      padding: 2px;
      outline: none;
      path {
        fill: #ec4242;
      }
      rect {
        fill: #ec4242;
      }
      transition: all 0.3s ease-in-out;
      &:hover {
        path {
          fill: #fff;
        }
        rect {
          fill: #fff;
        }
        background: #ec4242;
      }
    }
  }
  .filterButton {
    display: flex;
    min-width: unset;
    // padding: 0.375rem 0.75rem;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 565px;
    .MuiTypography-root {
      width: 500%;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 582px;
    .MuiTypography-root {
      width: 500%;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 598px;
  }
}

.c-sidebar-minimized.c-sidebar-fixed {
  z-index: 1037;
  width: 62px;
}

.element.style {
  top: 53px;
  height: 453px;
  right: 0px;
}

.c-sidebar .nav {
  text-decoration: none;
}
.sidebar-menu {
  position: absolute;
  top: 15px;
  right: -13px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s linear;
  transition: color 0.1s linear;
}
.sidebar-menu.menu-show {
  display: none;
  .MuiSvgIcon-root {
    transform: rotateY(180deg);
    margin-right: 1px;
    transition: all 0.3s ease-in-out;
  }
}
.c-sidebar:hover {
  .sidebar-menu.menu-show {
    display: flex;
  }
}

@media (max-width: 768px) {
  .sidebar-menu {
    display: none;
  }
}
.sidebar-menu:hover {
  background-color: var(--themeinfo);
  columns: #fff;
  color: #fff;
}
.c-sidebar.c-sidebar-minimized {
  width: 15px !important;
}
@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir='rtl'])
  .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
  ~ .c-wrapper {
  margin-left: 15px !important;
}
}

.c-sidebar-minimized.c-sidebar {
  .c-sidebar-nav {
    opacity: 0;
  }
  background-color: transparent !important;
  border-right: 2px solid #d6d5d5;
}

.c-sidebar-minimized.c-sidebar-hover {
  width: 192px !important;
  .c-sidebar-nav {
    opacity: 1;
  }
  border-right: 0px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.m-input:disabled {
  background-color: #d8dbe0 !important;
}
.text-black {
  color: #000;
}
.bleContainer {
  display:flex;
  flex-direction: column;
  margin-bottom: -150px;
}

.dashboard-tile-text{
  width: 140px !important;
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .dashboard-tile-text {
      width: 68px !important;
    }
}
