// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';
.c-avatar {
  display: inline-flex;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50px;
  overflow: hidden;
  .c-avatar-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50em;
  }
}
.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  border-bottom: 2px solid #a8adb6;
  border-right: 2px solid #a8adb6;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 2px;
  transition: all 0.5s ease-in-out;
  &.active {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    top: 9px;
  }
}
.avatar-image {
  height: 100%;
  object-fit: cover;
}
.no-row-data {
  text-align: center;
  margin: 2rem 0;
}

.c-sidebar {
  width: 197px;
  background: var(--blueGradient);

  .c-sidebar-nav {
    align-items: end;
    margin-left: 13px;
    padding-top: 10px;
    justify-content: space-evenly;
    img {
      margin-bottom: 7px;
    }
  }
  .c-sidebar-nav-item {
    width: inherit;
    display: inline-flex;
    &.submenu-list {
      div {
        flex-wrap: wrap;
        flex-grow: 1;
      }
    }
  }

  .text-margin {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  .text-margin-center {
    margin-left: 10px;
    margin-top: 4px;
  }
}
//.c-sidebar-minimized
@media screen and (min-width: 992px) {
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 197px;
  }
}

.badgeBgLight.badge-danger {
  background-color: rgba(249, 63, 63, 0.1);
}
.badgeBgLight.badge-success {
  background-color: rgba(0, 165, 7, 0.1);
}
.badgeBgLight.badge-info {
  background-color: rgba(36, 165, 237, 0.1);
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  vertical-align: middle;
}
.customDropdown {
  .customDropdownPre__control {
    border-radius: 0.25rem;
    border: 1px solid #d8dbe0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    background-color: #fff;
    padding: 0 8px;
    min-height: 35px;
    color: #768192;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .invalid-field__value-container,
  .valid-field__value-container {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .customDropdownPre__placeholder,
  .invalid-field__placeholder {
    font-family: var(--fontFase);
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 10px;
    color: var(--lightBlue) !important;
    opacity: 0.5;
  }
  .customDropdownPre__indicator-separator {
    display: none;
  }
  .customDropdownPre__single-value {
    line-height: 1;
    color: var(--lightBlue) !important;
  }
  .customDropdownPre__control--is-focused {
    color: #768192;
    border: 1px solid;
    background-color: #fff;
    border-color: #958bef;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
  }
  .valid-field__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(46, 184, 92, 0.25);
  }
  .invalid-field__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(229, 83, 83, 0.25);
  }
  .valid-field__single-value,
  .customDropdownPre__single-value,
  .invalid-field__single-value {
    color: #768192 !important;
  }
  .customDropdownPre__loading-indicator {
    color: #111111 !important;
  }
  .customDropdownPre__control--is-disabled {
    background-color: #f2f2f2 !important;
  }
}
.showDisable {
  .customDropdownPre__control--is-disabled {
    background-color: #f2f2f2 !important;
  }
}
.invalid-text {
  color: red;
  font-size: smaller;
}
.bottom-text {
  font-size: smaller;
}

.cursorHover {
  cursor: pointer;
}

.ReactTable {
  .rt-tr .action {
    transition: all 0.2s ease;
  }
  .rt-tr:hover .rt-td {
    background: #ebedef !important;
  }
  .rt-thead {
    .rt-th.-sort-asc {
      box-shadow: none !important;
    }
    .rt-th {
      border-right: none !important;
    }
  }
  .rt-th {
    box-shadow: none !important;
    &:before {
      content: '';
      background-image: url('../assets/images/sort.png');
      background-position: center;
      background-repeat: no-repeat;
      float: right;
      width: 20px;
      height: 10px;
    }
    &:focus {
      outline: none !important;
    }
  }
  .rt-th.-sort-asc {
    &:before {
      background-image: url('../assets/images/sort_asc.png');
    }
  }
  .rt-th.-sort-desc {
    &:before {
      background-image: url('../assets/images/sort_dsc.png');
    }
  }
  .rt-thead {
    &.-header {
      box-shadow: none !important;
    }
    .rt-td.-sort-asc {
      box-shadow: none !important;
    }
    .rt-td {
      border-right: none !important;
    }
  }
}
.jobDateClass {
  padding: 6px 16px;
  width: 100%;
  border: 1px solid #d8dbe0;
  color: #768192;
  border-radius: 4px;
}
.autoComplete.focus-visible,
.autoComplete:focus {
  color: #768192;
  border: 1px solid;
  background-color: #fff;
  border-color: #958bef;
  outline: 0;

  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}
.autoComplete.invalid-field.focus-visible,
.autoComplete.invalid-field:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 83, 83, 0.25);
}
.autoComplete.valid-field.focus-visible,
.autoComplete.valid-field:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 184, 92, 0.25);
}
.autoComplete:disabled {
  background-color: #d8dbe0 !important;
}
.valid-field__control,
.valid-field {
  border-color: #2eb85c !important;
  background-image: url('../assets/images/valid.svg') !important;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: right calc(0.375em + 0.1875rem) center;
  svg {
    opacity: 0;
  }
  min-height: 35px !important;
}
.invalid-field__control,
.invalid-field {
  border-color: #e55353 !important;
  background-image: url('../assets/images/error.svg') !important;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: right calc(0.375em + 0.1875rem) center;
  min-height: 35px !important;
  svg {
    opacity: 0;
  }
}
.valid-field__multi-value__remove {
  svg {
    opacity: 1;
  }
}

.valid-field__clear-indicator {
  svg {
    opacity: 1;
  }
}
.active_status {
  position: relative;
  display: flex;
  margin: -5px;
}

.Acknowledge_icon {
  content: '';
  width: 20%;
  height: 20%;
  top: 5px;
  background: url('../assets/icons/validBlack.svg') no-repeat;
  background-size: contain;
  background-position: bottom;
  left: 5px;
  padding-left: 30px;
  position: absolute;
  display: inline-block;
}
.Acknowledge {
  padding-left: 10px;
}

.active_status_0:after {
  content: '';
  width: calc(50%);
  height: 30px;
  background: url('../assets/images/d-remove.svg') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}
.active_status_1:after {
  content: '';
  width: calc(50%);
  height: 30px;
  background: url('../assets/images/valid.svg') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}

.active_status_2:after {
  content: '';
  width: calc(50%);
  height: 30px;
  background: url('../assets/images/t-warning.png') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}

.active_status_3:after {
  content: '';
  width: calc(50%);
  height: 30px;
  background: url('../assets/images/schedule.png') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}

.inactive_status {
  position: relative;
}
.inactive_status:after {
  content: '';
  width: 40px;
  height: 40px;
  background: url('../assets/images/invalid.png') no-repeat -30px -50px;
  top: -9px;
  background-size: contain;
  background-position: bottom;
  right: -50px;
  position: absolute;
  display: inline-block;
}
.btn-secondary {
  border: 0px;
}
.btn-secondary:focus:not(:hover) {
  background-color: #ced2d8 !important;
  border-color: #ced2d8 !important;
}
.btn-info:focus:not(:hover) {
  background-color: #39f !important;
  border-color: #39f !important;
}
.react-multi-carousel-item .device-img img {
  object-fit: contain;
}
[data-theme='warning'] .tippy-content {
  background-color: red !important;
  color: white !important;
}
[data-theme='warning'] .tippy-arrow {
  color: red !important;
}

.inlineMapWrapper {
  height: 450px;
  width: 100%;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  background-color: #fff;
  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -140px 0px 0px;
    z-index: 2;
  }
}
.bordered {
  border-radius: 0.25rem;
  background-clip: border-box;
  border: 1px solid #d8dbe0;
}
.fail:after {
  content: '';
  width: 30px;
  height: 30px;
  background: url('../assets/images/d-remove.svg') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}
.success:after {
  content: '';
  width: 30px;
  height: 30px;
  background: url('../assets/images/valid.svg') no-repeat -30px -50px;
  background-size: contain;
  background-position: bottom;
  display: inline-block;
}
.auto-complate {
  width: 40vw;
  @media only screen and (max-width: 1024px) {
    width: 200px;
  }
}

.dashbordrow {
  @media only screen and (max-width: 1440px) {
    justify-content: flex-start !important;
    width: 775px;
  }
}

.multiSelectComponent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
  @media only screen and (max-width: 1440px) {
    width: 150px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100px;
  }
}

.dashboardheadingwrapper {
  flex: 0 0 40%;
  @media only screen and (max-width: 1440px) {
    flex: 0 0 25%;
  }
  @media only screen and (max-width: 1024px) {
    flex: 0 0 10%;
  }
  @media only screen and (max-width: 653px) {
    flex: 0 0 15%;
  }
}

.auto-complate-scroll::-webkit-scrollbar {
  height: 8px;
}

/* Track */
.auto-complate-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.auto-complate-scroll::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  border-radius: 10px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #2b76d2 !important;
}

.multiselect {
  width: 60%;
  @media only screen and (max-width: 1024px) {
    width: 21%;
  }
}

.header-logo {
  width: 172px;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.ps .ps__rail-y {
  width: 7px;
}

.ps .ps__rail-y:hover {
  background-color: unset !important;
  opacity: unset !important;
}

.ps__rail-y:hover > .ps__thumb-y:hover {
  overflow-y: hidden;
  height: unset !important;
}
.breadcrumbsNav {
  color: var(--breadcrumText);
  padding-left: 0.2px;
  padding-top: 10px;
  line-height: normal !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  .MuiSvgIcon-root {
    color: var(--breadcrumText) !important;
    font-size: 16px !important;
    margin-top: -1px;
  }
  li {
    margin-right: 2px;
    list-style: none;
    &.active {
      color: var(--lightBlue);
    }
    &:last-child {
      margin: 0 0 0 0;
      color: #24a5ed;
    }
  }
}
.form-control::placeholder {
  opacity: 0.5;
  font-weight: normal;
  color: var(--lightBlue) !important;
}
.modal-footer {
  border-top: 0 none;
}

@media (max-width: 1290px) {
  .custom-img-handle {
    max-width: 110px;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: 1355px) {
  .custom-info-handle {
    max-width: 200px;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
