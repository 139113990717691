// Variable overrides

@import url('../assets/fonts/styles.css');

:root{
    --darkBlue:#0f1a26;
    --lightBlue:#284d73;
    --themeinfo:#24A5ED;
    --property:#CAF6F3;
    --gateway:#C4E3FC;
    --devices:#FDDFCB;
    --white:#ffffff;
    --black:#000000;
    --background-dropdownoption:#E9F6FD;
    --likeBlack:#2C2C2C;
    --likeGrey:rgba(44, 44, 44, 0.6);
    --likeDarkGrey:rgba(40, 77, 115, 0.05);
    --likeDarkGreyText:rgba(40, 77, 115, 0.06);
    --lightBlack:rgba(0, 0, 0, 0.5);
    --cardborder:rgba(31, 56, 108, 0.2);
    --borderBottomHeader:rgba(31, 56, 108, 0.05);
    --darkGray:#27313c;
    --lightGray:rgba(31, 56, 108, 0.06);
    --blueGradient:linear-gradient(180deg, #1F386C 0%, #366392 100%);
    --breadcrumText:rgba(40, 77, 115, 0.5);
    --subTitleColor:#9A9A9A;
    --clientName:#1F386C;
    --seaGreenline:#72D9D0;
    --seaBlueline:#89CAFF;
    --paleOrangeline:#FDDFCB;
    --greyBlueline:rgba(103, 133, 196, 0.6);
    --blueLine:#B8BFFF;    
    --tabbedsectionTxtColor: rgba(31, 56, 108, 0.5);
    --clientDetailCardsHeadingColor:rgba(31, 56, 108, 0.4);
    --fontFase:'Helvetica', Arial , sans-serif;
    --highAlertBg: rgba(249, 63, 63, 0.1);
    --mediumAlertBg: rgba(36, 165, 237, 0.1);
    --lowAlertBg: rgba(0, 165, 7, 0.1);
    --highAlertText: #F93F3F;
    --mediumAlertText: #24A5ED;
    --lowAlertText: #00A507;
    --tableAlertGrey:#rgba(44, 44, 44, 0.6);
    --alertNameText:#1F386C;
    --reportedDateTimeBg: #88A4EE;
}